
import { defineComponent } from "vue";
import base from "@/api/base.js";
import { Form } from "vee-validate";

export default defineComponent({
  name: "myinfo",

  components: {
    Form,
  },

  data() {
    return {
      userData: {},
      userId: "",
    };
  },

  mounted() {
    this.userId = localStorage.getItem("userId");
    this.getData();
  },

  methods: {
    getData() {
      base
        .getUser(this.userId)
        .then((res) => {
          console.log(res);
          this.userData = res.data;
        })
        .catch((err) => {
          console.log(err.response);
          const value = Object.values(err.response.data);
          alert(value);
        });
    },
    async editData() {
      base
        .updateUser(this.userData)
        .then((res) => {
          console.log(res);
          if (res.status == 200) {
            alert("사용자 정보를 수정했습니다.");
          } else {
            alert(res.status);
          }
        })
        .catch((err) => {
          console.log(err.response);
          const value = Object.values(err.response.data);
          alert(value);
        });
    },
  },
});
